import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/main.scss";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router.js";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCalendarAlt,
  faCopy,
  faEnvelope,
  faGift,
  faMapLocationDot,
  faPaperPlane,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faEnvelope,
  faCalendarAlt,
  faGift,
  faCopy,
  faMapLocationDot,
  faPaperPlane,
  faUser
);

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueAxios, axios);

app.component("fa-icon", FontAwesomeIcon);

app.mount("#app");
