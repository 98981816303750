<template>
  <div class="wrapper">
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
.wrapper {
  max-width: 400px;
  margin: 0 auto;
}
</style>
